import { Theme } from '../theme'

function getResponsiveValue<T extends number | string>(
  name: string,
  values: undefined | T | Array<T>,
  theme: Theme,
  // eslint-disable-next-line @typescript-eslint/ban-types
): {} {
  // Return empty object on undefined value
  if (values === undefined) return {}

  // If value is a single value return object with this key/value pair
  if (!Array.isArray(values)) {
    return { [name]: values }
  }

  // If value is a list, map this list to the responsive values, starting at the bottom
  // and defining the last value as the default
  const result: { [key: string]: T | { [key: string]: T } } = { [name]: values[0] }
  for (let i = 1; i < values.length; i++) {
    result[theme.breakpoints.up(theme.breakpoints.keys[i])] = {
      [name]: values[i],
    }
  }
  return result
}

export default getResponsiveValue
