import { CreateCSSProperties, makeStyles } from '@mui/styles'

import { Theme } from './theme'
import getColorValue from './theme-utils/get-color-value'

export interface UseBackgroundColorProps {
  backgroundColor?: string
}

const useBackgroundColor = makeStyles((theme: Theme) => ({
  backgroundColorClass: (props: UseBackgroundColorProps): CreateCSSProperties => {
    const backgroundColor = getColorValue(theme)({ color: props.backgroundColor })

    if (!backgroundColor) return {}

    return {
      backgroundColor,
    }
  },
}))

export default useBackgroundColor
