import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { darken } from './colorManipulator'
import { Theme } from './theme'
import getColorValue from './theme-utils/get-color-value'

export interface UseColorProps {
  color?: string
  useHover?: boolean
}

const useColor = makeStyles((theme: Theme) => ({
  colorClass: (props: UseColorProps): CreateCSSProperties => {
    const color = getColorValue(theme)(props)

    if (!color) return {}

    return {
      color,
      ...(props.useHover && {
        '&:hover': {
          color: darken(color, 0.2),
        },
      }),
    }
  },
}))

export default useColor
