import { assertNever } from '../../utilities/assertNever'
import { Theme } from '../../design-tokens/theme'

export type LogoColor = 'default' | 'white' | 'black'

export interface Colors {
  textColor: string
  iconTopColor: string
  iconBottomColor: string
}

function getLogoColors(color: LogoColor, theme: Theme): Colors {
  switch (color) {
    case 'default':
      return {
        textColor: theme.palette.text.primary,
        iconTopColor: theme.palette.primary.main,
        iconBottomColor: theme.palette.primaryLight,
      }
    case 'white':
      return {
        textColor: theme.palette.white,
        iconTopColor: theme.palette.white,
        iconBottomColor: theme.palette.white,
      }
    case 'black':
      return {
        textColor: theme.palette.text.primary,
        iconTopColor: theme.palette.text.primary,
        iconBottomColor: theme.palette.text.primary,
      }
    default:
      return assertNever(color)
  }
}

export default getLogoColors
