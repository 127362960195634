import FigtreeWoff2 from './fonts/figtree.woff2'

const figtreeFontFace = `
  @font-face {
    font-family: 'Figtree';
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local('Figtree'), local('Figtree-Regular'), url(${FigtreeWoff2 as string}) format('woff2');
  }

  @font-face {
    font-family: 'Figtree Fallback';
    src: local(Arial);
    size-adjust: 96%;
    ascent-override: 96%;
    descent-override: normal;
    line-gap-override: normal;
  }

  body {
    font-family: "Figtree", "Figtree Fallback";
  }
`

export default figtreeFontFace
