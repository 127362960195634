import { ButtonProps as MuiButtonProps, default as MuiButton } from '@mui/material/Button'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'

import { assertNever } from '../../utilities/assertNever'
import { darken } from '../../design-tokens/colorManipulator'
import { filterProps, getColorValue } from '../../design-tokens/design-utils'
import { Theme } from '../../design-tokens/theme'
import useFontWeight from '../../design-tokens/useFontWeight'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'
import useTheme from '../../design-tokens/useTheme'
import useRenderLock from '../../logical/useRenderLock'

const useVariants = makeStyles(({ palette }: Theme) => ({
  danger: {
    color: palette.white,
    backgroundColor: palette.danger,
    '&:hover': {
      background: darken(palette.danger, 0.1),
    },
  },
}))

const useStyles = makeStyles({
  root: {
    height: 48,
  },
})

type Variants = 'primary' | 'secondary' | keyof ReturnType<typeof useVariants>

export interface Props
  extends Pick<MuiButtonProps, 'sx' | 'disabled' | 'fullWidth' | 'onClick' | 'type' | 'className'>,
    UseSpacingProps {
  variant: Variants
  customBackgroundColor?: string
  customColor?: string
  children?: React.ReactNode
}

type ButtonRef = HTMLElementTagNameMap['button']

// eslint-disable-next-line no-shadow
export const Button = React.forwardRef<ButtonRef, Props>(function Button(
  { variant, customBackgroundColor, customColor, className, ...props },
  ref,
) {
  const classes = useStyles()
  const variantClasses = useVariants()
  const theme = useTheme()
  const locked = useRenderLock()
  const { spacingClass } = useSpacing(props)
  const { fontWeightClass } = useFontWeight({ fontWeight: 'bold' })

  const convertVariant = (): Partial<MuiButtonProps> => {
    switch (variant) {
      case 'primary':
        return { variant: 'contained', color: 'primary' }
      case 'secondary':
        return { variant: 'outlined', color: 'primary' }
      case 'danger':
        return {
          variant: 'contained',
          className: classNames(variantClasses[variant], className),
        }
      default:
        return assertNever(variant)
    }
  }

  const getStyle = () => {
    const style: { [key: string]: string } = {}

    if (customBackgroundColor) {
      const paletteColor = getColorValue(customBackgroundColor)({ theme })
      if (paletteColor) {
        style.background = paletteColor as string
      }
    }

    if (customColor) {
      const paletteColor = getColorValue(customColor)({ theme })
      if (paletteColor) {
        style.color = paletteColor as string
      }
    }

    return style
  }

  // If the button is of type submit, always prevent it from being activated before JS is loaded on the client
  // to prevent unwanted form submit behavior
  const disabled = props.type === 'submit' ? locked || props.disabled : props.disabled

  return (
    <MuiButton
      {...filterProps(props, ...UseSpacingProp)}
      classes={{
        root: classes.root,
      }}
      style={getStyle()}
      className={classNames(className, spacingClass, fontWeightClass)}
      ref={ref}
      disabled={disabled}
      {...convertVariant()}
    />
  )
})

export default Button
