import classNames from 'classnames'
import * as React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { filterProps } from '../../design-tokens/design-utils'
import useFullWidth, { UseFullWidthProps } from '../../design-tokens/useFullWidth'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'

type BaseLinkRef = HTMLElementTagNameMap['a']
export interface BaseLinkProps extends UseSpacingProps, UseFullWidthProps {
  to: string
  external?: boolean
  blank?: boolean
  download?: string
  className?: string
  children?: React.ReactNode
  onClick?: () => void
}

// eslint-disable-next-line no-shadow
export const BaseLink = React.forwardRef<BaseLinkRef, BaseLinkProps>(function BaseLink(
  { external, to, className, blank, fullWidth, ...rest },
  ref,
) {
  const { spacingClass } = useSpacing(rest)
  const { fullWidthClass } = useFullWidth({ fullWidth })

  const componentProps = {
    className: classNames(className, spacingClass, fullWidthClass),
    ...filterProps(rest, ...UseSpacingProp),
    ...(blank && { target: `_blank`, rel: 'noopener' }),
  }

  return external ? (
    <a href={to} ref={ref} {...componentProps} />
  ) : (
    <RouterLink to={to} innerRef={ref} {...componentProps} />
  )
})

export default BaseLink
