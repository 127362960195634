import { default as MuiListItem, ListItemProps } from '@mui/material/ListItem'
import * as React from 'react'

type ListItemRef = HTMLElementTagNameMap['li']

// eslint-disable-next-line no-shadow
const ListItem = React.forwardRef<ListItemRef, Omit<ListItemProps, 'button'>>(function ListItem(
  { disableGutters, ...props },
  ref,
) {
  return <MuiListItem {...props} ref={ref} disableGutters />
})

ListItem.defaultProps = {
  disableGutters: true,
}

export default ListItem
