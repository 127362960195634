import { createStyles, withStyles } from '@mui/styles'
import * as React from 'react'

import { Theme } from './theme'

/* eslint-disable @typescript-eslint/restrict-template-expressions  */
const useStyles = createStyles(({ fontSizing, palette }: Theme) => ({
  '@global': {
    'html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video':
      {
        margin: '0',
        padding: '0',
        border: '0',
        fontSize: '100%',
        fontFamily: "'Figtree', 'Figtree Fallback'",
        verticalAlign: 'baseline',
      },
    'article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section': {
      display: 'block',
    },
    'html, body': {
      height: '100%;',
    },
    body: {
      lineHeight: 1,
    },
    'ol, ul': {
      listStyle: 'none',
    },
    'blockquote, q': {
      quotes: 'none',
      '&:before, &:after': {
        content: 'none',
      },
    },
    table: {
      borderCollapse: 'collapse',
      borderSpacing: 0,
    },
    html: {
      boxSizing: 'border-box',
      fontSize: fontSizing.default,
      color: palette.text.primary,
    },
    '*, *:before, *:after': {
      boxSizing: 'inherit',
    },
    a: {
      textDecoration: 'none',
    },
    '#root': {
      height: '100%',
    },
  },
}))

const ResetCSS: React.FC<React.PropsWithChildren<unknown>> = () => null

export default withStyles(useStyles)(ResetCSS)
