import { makeStyles, CreateCSSProperties } from '@mui/styles'

export interface UseTextTransformProps {
  textTransform?: 'none' | 'uppercase' | 'lowercase' | 'capitalize'
}

const useTextTransform = makeStyles({
  textTransformClass: ({ textTransform }: UseTextTransformProps): CreateCSSProperties => ({
    textTransform,
  }),
})

export default useTextTransform
