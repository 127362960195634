import * as React from 'react'

import { Colors } from '../get-logo-colors'

type EversportsLogoIconRef = SVGElementTagNameMap['svg']
interface Props {
  colors: Colors
  className?: string
  ref?: React.Ref<EversportsLogoIconRef>
}

const EversportsLogoIcon: React.FC<React.PropsWithChildren<Props>> = ({ colors, className, ref }) => (
  <svg viewBox="0 0 201 206" className={className} ref={ref}>
    <title>Eversports</title>
    <path
      d="M195.944 135.45C196.625 134.424 197.673 134.144 198.469 134.448C198.855 134.596 199.086 134.848 199.189 135.086C199.276 135.286 199.34 135.617 199.15 136.194C185.902 176.333 152.443 200.108 116.006 205.04C79.5831 209.971 40.552 195.989 16.5892 161.081C3.06906 140.937 -1.33287 122.049 0.336843 105.587C2.0058 89.1333 9.77174 74.7908 21.11 63.7264C43.8637 41.5224 80.7248 32.8258 109.852 47.2651C110.304 47.4891 110.46 47.7737 110.519 47.9681C110.583 48.1821 110.581 48.4661 110.467 48.7918C110.239 49.4383 109.593 50.1292 108.581 50.0854C88.3373 49.2099 73.0262 55.5526 62.82 66.0452C52.5987 76.5534 47.7061 91.0152 48.0351 105.848C48.6925 135.492 70.0972 166.461 111.134 172.187C130.543 174.895 170.343 174.037 195.944 135.45Z"
      fill={colors.iconTopColor}
    />
    <path
      d="M19.6898 45.2332C18.7444 46.044 17.9932 45.8219 17.6845 45.5148C17.5265 45.3577 17.4655 45.1774 17.4627 45.005C17.4602 44.8417 17.5079 44.4878 17.8844 43.9597C53.6688 -6.23456 137.126 -21.7655 185.224 42.0274C199.832 61.403 202.531 80.8394 198.043 97.8619C193.529 114.986 181.633 130.085 166.39 140.473C135.83 161.298 92.8981 162.559 71.5502 124.445C71.2011 123.822 71.2983 123.172 71.6673 122.633C72.0581 122.062 72.7249 121.686 73.4584 121.871C99.7608 128.514 119.501 126.219 132.863 118.643C146.324 111.012 153.209 98.0545 153.576 84.0433C154.303 56.2647 129.602 25.7086 83.8588 23.8041C56.7587 22.6758 37.1107 30.2932 19.6898 45.2332Z"
      fill={colors.iconBottomColor}
    />
  </svg>
)

export default EversportsLogoIcon
