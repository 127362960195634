import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { Theme } from './theme'
import getResponsiveValue from './theme-utils/get-responsive-value'
import resolveFullWidth from './theme-utils/resolve-full-width'

export interface UseFullWidthProps {
  fullWidth?: boolean | Array<boolean>
}

const useFullWidth = makeStyles((theme: Theme) => ({
  fullWidthClass: ({ fullWidth }: UseFullWidthProps): CreateCSSProperties =>
    getResponsiveValue('width', resolveFullWidth(fullWidth), theme),
}))

export default useFullWidth
