function getWidth(fullWidth: boolean): string {
  return fullWidth ? '100%' : 'initial'
}

const resolveFullWidth = (fullWidths?: boolean | Array<boolean>): undefined | string | Array<string> => {
  if (fullWidths !== undefined) {
    if (!Array.isArray(fullWidths)) {
      return getWidth(fullWidths)
    }

    return fullWidths.map(getWidth)
  }

  return fullWidths
}

export default resolveFullWidth
