import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { Space, Theme } from './theme'
import getResponsiveValue from './theme-utils/get-responsive-value'

export interface UseHeightProps {
  height?: Space | Array<Space>
}

const useHeight = makeStyles((theme: Theme) => ({
  heightClass: ({ height }: UseHeightProps): CreateCSSProperties => getResponsiveValue('height', height, theme),
}))

export default useHeight
