import { CircularProgressProps, default as MuiCircularProgress } from '@mui/material/CircularProgress'
import * as React from 'react'

// The default element type of MUI's CircularProgress is 'div' and the change of this prop is not allowed
type CircularProgressRef = HTMLElementTagNameMap['div']

// eslint-disable-next-line no-shadow
export const CircularProgress = React.forwardRef<CircularProgressRef, CircularProgressProps>(
  function CircularProgress(props, ref) {
    return <MuiCircularProgress {...props} color="secondary" ref={ref} />
  },
)

export default CircularProgress
