import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { FontSizing, Theme } from '../design-tokens/theme'

import { getResponsiveValue } from './theme-utils'
import resolveFontSizing from './theme-utils/resolve-font-sizing'

export interface UseFontSizeProps {
  fontSize?: FontSizing | Array<FontSizing>
}

const useFontSize = makeStyles((theme: Theme) => ({
  fontSizeClass: ({ fontSize }: UseFontSizeProps): CreateCSSProperties =>
    getResponsiveValue('fontSize', resolveFontSizing(theme)(fontSize), theme),
}))

export default useFontSize
