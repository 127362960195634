import * as React from 'react'

import { Colors } from '../get-logo-colors'

type EversportsLogoHorizontalRef = SVGElementTagNameMap['svg']

interface Props {
  colors: Colors
  className?: string
  ref?: React.Ref<EversportsLogoHorizontalRef>
}

const EversportsLogoHorizontal: React.FC<React.PropsWithChildren<Props>> = ({ colors, className, ref }) => (
  <svg viewBox="0 0 858 175" className={className} ref={ref}>
    <title>Eversports</title>
    <path
      d="M165.632 114.496C166.207 113.629 167.093 113.392 167.766 113.649C168.093 113.774 168.288 113.987 168.375 114.189C168.449 114.358 168.503 114.638 168.342 115.125C157.143 149.055 128.861 169.152 98.0603 173.321C67.2717 177.489 34.2787 165.67 14.0229 136.162C2.59429 119.134 -1.12667 103.168 0.284734 89.2534C1.69551 75.3446 8.26007 63.2209 17.8444 53.8682C37.0781 35.0991 68.2368 27.7478 92.8585 39.9534C93.2404 40.1427 93.3724 40.3833 93.4217 40.5476C93.4759 40.7285 93.4747 40.9686 93.3777 41.2439C93.1851 41.7904 92.6391 42.3744 91.784 42.3374C74.6717 41.5973 61.7292 46.9588 53.1019 55.8282C44.4618 64.7108 40.3261 76.9354 40.6041 89.4734C41.1599 114.532 59.2533 140.71 93.9414 145.55C110.348 147.839 143.991 147.114 165.632 114.496Z"
      fill={colors.iconTopColor}
    />
    <path
      d="M16.6439 38.2358C15.8447 38.9211 15.2097 38.7334 14.9487 38.4738C14.8152 38.341 14.7636 38.1886 14.7613 38.0428C14.7591 37.9048 14.7995 37.6056 15.1177 37.1592C45.3664 -5.27008 115.913 -18.3984 156.57 35.5258C168.919 51.9041 171.2 68.3337 167.406 82.7229C163.59 97.1983 153.535 109.961 140.65 118.742C114.818 136.345 78.527 137.411 60.4815 105.193C60.1865 104.667 60.2686 104.118 60.5806 103.662C60.9109 103.179 61.4746 102.861 62.0946 103.018C84.3281 108.634 101.014 106.693 112.309 100.289C123.688 93.8383 129.508 82.8857 129.818 71.042C130.433 47.5607 109.553 21.7315 70.8861 20.1216C47.9783 19.1679 31.3698 25.6069 16.6439 38.2358Z"
      fill={colors.iconBottomColor}
    />
    <path
      d="M830.298 44.0745C826.639 44.0745 823.154 44.4229 820.018 45.2941C816.708 46.1652 813.746 47.3848 811.306 49.1271C808.693 50.8694 806.602 53.1344 805.208 55.9221C803.64 58.7098 802.943 61.8459 802.943 65.679C802.943 69.5121 803.64 72.6482 805.034 75.2617C806.428 77.8751 808.345 79.9659 810.61 81.5339C812.875 83.102 815.488 84.4959 818.276 85.5412C821.063 86.5866 823.851 87.4578 826.639 88.3289C829.426 89.2001 832.04 89.897 834.305 90.9424C836.57 91.8135 838.486 93.0331 839.88 94.427C841.274 95.8208 841.971 97.5631 842.145 99.6539C842.145 101.57 841.623 103.138 840.577 104.184C839.532 105.403 837.964 106.275 836.221 106.797C834.479 107.32 832.388 107.668 829.949 107.668C827.161 107.668 824.374 107.32 821.238 106.623C818.276 105.926 815.314 104.881 812.352 103.487C809.564 102.093 806.951 100.525 804.86 98.4343L799.11 109.933C801.55 112.198 804.511 114.115 807.822 115.683C811.132 117.251 814.617 118.471 818.276 119.168C821.934 120.039 825.593 120.387 829.252 120.387C833.085 120.387 836.744 119.865 840.054 118.993C843.539 118.122 846.501 116.728 849.289 114.986C851.902 113.244 853.993 110.805 855.561 108.017C857.129 105.229 857.826 101.919 858 98.0858C858 94.2527 857.303 90.9424 855.909 88.3289C854.516 85.7155 852.599 83.6247 850.334 81.8824C848.069 80.1401 845.456 78.9205 842.668 77.7009C839.88 76.6555 837.093 75.6101 834.131 74.9132C831.343 74.042 828.729 73.3451 826.29 72.474C824.025 71.6028 822.109 70.5574 820.541 69.3378C819.147 68.1182 818.45 66.5501 818.276 64.6336C818.276 62.8913 818.798 61.4975 819.67 60.2779C820.541 59.2325 821.934 58.3613 823.503 57.8386C825.071 57.3159 826.987 57.1417 829.078 57.1417C831.343 57.1417 833.608 57.4902 836.047 58.0129C838.486 58.5356 840.926 59.2325 843.539 60.2779C845.978 61.3232 848.417 62.5429 850.508 63.7625L856.432 51.7406C854.167 50.1725 851.554 48.7787 848.766 47.5591C845.978 46.5137 842.842 45.6425 839.706 44.9456C836.744 44.4229 833.434 44.0745 830.298 44.0745Z"
      fill={colors.textColor}
    />
    <path
      d="M792.664 102.442C790.573 103.487 788.482 104.358 786.74 104.881C784.998 105.404 783.081 105.752 781.513 105.752C779.771 105.752 778.377 105.404 777.157 104.881C775.938 104.358 775.066 103.139 774.37 101.745C773.673 100.177 773.324 98.2601 773.324 95.6467V59.581H795.451V47.5592H773.324V27H757.295C757.295 58.5357 757.295 57.6645 757.295 98.4344C757.295 102.267 757.818 105.578 759.037 108.365C760.083 111.153 761.651 113.418 763.567 115.16C765.484 116.903 767.749 118.297 770.188 118.994C772.627 119.865 775.415 120.213 778.203 120.213C781.339 120.213 784.475 119.69 787.611 118.819C790.747 117.948 793.709 116.554 796.671 114.812L792.664 102.442Z"
      fill={colors.textColor}
    />
    <path
      d="M725.585 44.7714H709.382V119.516H725.585V80.4886C726.108 76.1328 727.327 72.474 729.592 69.1636C731.683 66.0275 734.645 63.414 738.478 61.8459C742.137 60.1036 746.493 59.4067 751.545 59.5809V44.0745C745.621 44.0745 740.395 45.4683 736.039 48.0818C731.683 50.6952 728.198 54.5283 725.585 59.5809V44.7714V44.7714Z"
      fill={colors.textColor}
    />
    <path
      d="M663.733 58.1872C668.263 58.1872 672.096 59.2326 675.581 61.3233C679.066 63.4141 681.679 66.2018 683.596 69.8606C685.512 73.5194 686.558 77.701 686.558 82.5794C686.558 87.4579 685.512 91.6394 683.596 95.2982C681.679 98.957 679.066 101.745 675.581 103.835C672.096 105.926 668.263 106.972 663.733 106.972C659.203 106.972 655.196 105.926 651.711 103.835C648.227 101.745 645.613 98.957 643.697 95.2982C641.78 91.6394 640.735 87.4579 640.735 82.5794C640.735 77.701 641.78 73.5194 643.697 69.8606C645.613 66.2018 648.227 63.4141 651.711 61.3233C655.196 59.2326 659.203 58.1872 663.733 58.1872ZM663.733 44.2488C657.984 44.2488 652.583 45.1199 647.878 47.0365C643.174 48.7788 638.993 51.3922 635.508 54.8768C632.023 58.1872 629.41 62.1945 627.493 66.8987C625.577 71.6029 624.706 76.6556 624.706 82.4052C624.706 87.9805 625.751 93.2074 627.493 97.9117C629.41 102.616 632.023 106.623 635.508 109.934C638.993 113.244 643 115.857 647.878 117.774C652.583 119.516 657.984 120.562 663.733 120.562C669.483 120.562 674.71 119.69 679.588 117.774C684.293 116.032 688.474 113.418 691.959 109.934C695.443 106.623 698.057 102.616 699.973 97.9117C701.89 93.2074 702.761 88.1548 702.761 82.4052C702.761 76.6556 701.715 71.6029 699.973 66.8987C698.057 62.1945 695.443 58.1872 691.959 54.8768C688.474 51.5664 684.467 48.953 679.588 47.0365C674.71 45.1199 669.483 44.2488 663.733 44.2488Z"
      fill={colors.textColor}
    />
    <path
      d="M580.8 106.275C576.27 106.275 572.437 105.229 568.952 103.139C565.468 101.048 562.854 98.2601 560.938 94.6013C559.021 90.9425 557.976 86.7609 557.976 81.8825C557.976 77.1783 559.021 72.9968 560.938 69.3379C562.854 65.6791 565.468 62.8914 568.952 60.8006C572.437 58.7099 576.27 57.6645 580.8 57.6645C584.11 57.6645 587.246 58.1872 590.034 59.4068C592.822 60.6264 595.261 62.3687 597.177 64.4595C599.268 66.7245 600.836 69.1637 601.882 72.1256C602.927 75.0875 603.624 78.2237 603.624 81.7083C603.624 86.5867 602.579 90.7682 600.662 94.4271C598.745 98.0859 596.132 100.874 592.647 102.964C589.163 105.229 585.33 106.275 580.8 106.275ZM584.11 44.2488C578.186 44.2488 573.134 45.4684 568.778 47.7334C564.422 49.9984 560.763 53.3087 557.976 57.8387V44.9457H541.772V147.045H557.976V106.623C560.763 110.979 564.248 114.289 568.778 116.554C573.134 118.819 578.361 120.039 584.459 120.039C589.86 120.039 594.738 119.168 599.094 117.251C603.45 115.509 607.109 112.895 610.245 109.585C613.381 106.275 615.82 102.267 617.388 97.7374C619.13 93.0332 619.827 87.9805 620.002 82.4052C620.002 76.6556 619.13 71.4287 617.388 66.8987C615.646 62.1945 613.207 58.1872 610.07 54.8768C606.934 51.5664 603.101 48.953 598.745 47.0365C594.39 45.1199 589.511 44.2488 584.11 44.2488Z"
      fill={colors.textColor}
    />
    <path
      d="M509.54 44.0745C505.881 44.0745 502.396 44.4229 499.26 45.2941C495.95 46.1652 492.988 47.3848 490.549 49.1271C487.935 50.8694 485.844 53.1344 484.451 55.9221C482.883 58.7098 482.186 61.8459 482.186 65.679C482.186 69.5121 482.883 72.6482 484.276 75.2617C485.67 77.8751 487.587 79.9659 489.852 81.5339C492.117 83.102 494.73 84.4959 497.518 85.5412C500.306 86.5866 503.093 87.4578 505.881 88.3289C508.669 89.2001 511.282 89.897 513.547 90.9424C515.812 91.8135 517.729 93.0331 519.122 94.427C520.516 95.8208 521.213 97.5631 521.387 99.6539C521.387 101.57 520.865 103.138 519.819 104.184C518.774 105.403 517.206 106.275 515.464 106.797C513.721 107.32 511.63 107.668 509.191 107.668C506.404 107.668 503.616 107.32 500.48 106.623C497.518 105.926 494.556 104.881 491.594 103.487C488.806 102.093 486.193 100.525 484.102 98.4343L478.353 109.933C480.792 112.198 483.754 114.115 487.064 115.683C490.374 117.251 493.859 118.471 497.518 119.168C501.177 120.039 504.836 120.387 508.494 120.387C512.327 120.387 515.986 119.865 519.297 118.993C522.781 118.122 525.743 116.728 528.531 114.986C531.144 113.244 533.235 110.805 534.803 108.017C536.371 105.229 537.068 101.919 537.242 98.0858C537.242 94.2527 536.545 90.9424 535.152 88.3289C533.758 85.7155 531.841 83.6247 529.576 81.8824C527.311 80.1401 524.698 78.9205 521.91 77.7009C519.122 76.6555 516.335 75.6101 513.373 74.9132C510.585 74.042 507.972 73.3451 505.532 72.474C503.267 71.6028 501.351 70.5574 499.783 69.3378C498.389 68.1182 497.692 66.5501 497.518 64.6336C497.518 62.8913 498.041 61.4975 498.912 60.2779C499.783 59.2325 501.177 58.3613 502.745 57.8386C504.313 57.3159 506.229 57.1417 508.32 57.1417C510.585 57.1417 512.85 57.4902 515.289 58.0129C517.729 58.5356 520.168 59.2325 522.781 60.2779C525.22 61.3232 527.66 62.5429 529.75 63.7625L535.674 51.7406C533.409 50.1725 530.796 48.7787 528.008 47.5591C525.22 46.5137 522.084 45.6425 518.948 44.9456C515.812 44.4229 512.676 44.0745 509.54 44.0745Z"
      fill={colors.textColor}
    />
    <path
      d="M452.044 44.7714H435.84V119.516H452.044V80.4886C452.566 76.1328 453.786 72.474 456.051 69.1636C458.142 66.0275 461.104 63.414 464.937 61.8459C468.596 60.1036 472.951 59.4067 478.004 59.5809V44.0745C472.08 44.0745 466.853 45.4683 462.498 48.0818C458.142 50.6952 454.657 54.5283 452.044 59.5809V44.7714Z"
      fill={colors.textColor}
    />
    <path
      d="M370.852 76.4814C371.549 72.4741 372.769 68.9895 374.685 66.2018C376.602 63.2399 379.215 61.1491 382.177 59.581C385.313 58.013 388.798 57.316 392.631 57.1418C396.813 57.1418 400.297 58.013 403.259 59.4068C406.221 60.9749 408.66 63.2399 410.403 66.0275C412.145 68.9895 413.19 72.2998 413.364 76.4814H370.852ZM392.631 44.2488C387.056 44.2488 382.003 45.2942 377.299 47.0365C372.595 48.7788 368.762 51.3922 365.277 54.8768C361.967 58.1872 359.353 62.1945 357.437 66.8987C355.694 71.6029 354.649 76.6556 354.649 82.4052C354.649 87.9805 355.52 93.2075 357.437 97.7374C359.179 102.442 361.792 106.275 365.103 109.759C368.413 113.07 372.42 115.683 377.125 117.425C381.829 119.168 387.056 120.213 392.805 120.213C399.252 120.213 405.176 119.168 410.403 116.903C415.629 114.638 420.159 111.676 423.818 107.494L415.281 98.4344C412.493 101.222 409.357 103.487 405.698 104.881C402.04 106.449 398.206 107.146 394.199 107.146C390.192 107.146 386.707 106.449 383.571 104.881C380.435 103.313 377.647 101.222 375.557 98.4344C373.466 95.6467 371.898 92.3363 371.027 88.5032H427.477C428 81.7083 427.651 75.6102 426.606 70.2091C425.561 64.8079 423.47 60.1037 420.682 56.2707C417.894 52.4376 414.061 49.4757 409.357 47.3849C404.827 45.2942 399.252 44.2488 392.631 44.2488Z"
      fill={colors.textColor}
    />
    <path
      d="M329.386 119.516L357.96 44.7715H341.93L321.371 103.661L300.464 44.7715H283.563L312.66 119.516H329.386Z"
      fill={colors.textColor}
    />
    <path
      d="M229.203 76.4814C229.9 72.4741 231.12 68.9895 233.036 66.2018C234.953 63.2399 237.566 61.1491 240.528 59.581C243.664 58.013 247.149 57.316 250.982 57.1418C255.164 57.1418 258.648 58.013 261.61 59.4068C264.572 60.9749 267.011 63.2399 268.754 66.0275C270.496 68.9895 271.541 72.2998 271.716 76.4814H229.203ZM250.982 44.2488C245.407 44.2488 240.354 45.2942 235.65 47.0365C231.12 48.7788 227.113 51.3922 223.628 54.8768C220.318 58.1872 217.704 62.1945 215.788 66.8987C214.045 71.6029 213 76.6556 213 82.4052C213 87.9805 213.871 93.2075 215.788 97.7374C217.53 102.442 220.143 106.275 223.454 109.759C226.764 113.07 230.771 115.683 235.476 117.425C240.18 119.168 245.407 120.213 251.156 120.213C257.603 120.213 263.527 119.168 268.754 116.903C273.981 114.638 278.511 111.676 282.169 107.494L273.632 98.4344C270.844 101.222 267.708 103.487 264.049 104.881C260.391 106.449 256.558 107.146 252.55 107.146C248.543 107.146 245.058 106.449 241.922 104.881C238.786 103.313 235.998 101.222 233.908 98.4344C231.817 95.6467 230.249 92.3363 229.378 88.5032H285.828C286.351 81.7083 286.002 75.6102 284.957 70.2091C283.912 64.8079 281.821 60.1037 279.033 56.2707C276.246 52.4376 272.412 49.4757 267.708 47.3849C263.178 45.2942 257.603 44.2488 250.982 44.2488Z"
      fill={colors.textColor}
    />
  </svg>
)

export default EversportsLogoHorizontal
