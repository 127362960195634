import { makeStyles, CreateCSSProperties } from '@mui/styles'
import deepmerge from 'deepmerge'

import { Theme } from './theme'
import { getResponsiveValue } from './theme-utils'

type AlignSelf = 'auto' | 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'

export interface UseFlexChildProps {
  flexGrow?: number | Array<number>
  flexShrink?: number | Array<number>
  alignSelf?: AlignSelf | Array<AlignSelf>
}

export const UseFlexChildSizingProps: Array<keyof UseFlexChildProps> = ['flexGrow', 'flexShrink', 'alignSelf']

const useFlexChild = makeStyles((theme: Theme) => ({
  flexChildClass: ({ flexGrow, flexShrink, alignSelf }: UseFlexChildProps) =>
    deepmerge.all([
      getResponsiveValue('flex-grow', flexGrow, theme),
      getResponsiveValue('flex-shrink', flexShrink, theme),
      getResponsiveValue('align-self', alignSelf, theme),
    ]) as CreateCSSProperties,
}))

export default useFlexChild
