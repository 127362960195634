import { createGenerateClassName, StylesProvider as MuiStyleProvider } from '@mui/styles'
import { SheetsRegistry } from 'jss'
import * as React from 'react'
import { ThemeProvider } from '@mui/material/styles'

import { Theme } from './theme'

export { createGenerateClassName }

interface Props {
  generateClassName?: ReturnType<typeof createGenerateClassName>
  sheetsRegistry?: SheetsRegistry
  sheetsManager?: WeakMap<Record<string, unknown>, any>
  disableGeneration?: boolean
  theme: Theme
}

const StyleProvider = ({ children, theme, ...rest }: React.PropsWithChildren<Props>) => (
  <MuiStyleProvider {...rest}>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </MuiStyleProvider>
)

export default StyleProvider
