import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { Space, Theme } from './theme'
import getResponsiveValue from './theme-utils/get-responsive-value'

export interface UseWidthProps {
  width?: Space | Array<Space>
}

const useWidth = makeStyles((theme: Theme) => ({
  widthClass: ({ width }: UseWidthProps): CreateCSSProperties => getResponsiveValue('width', width, theme),
}))

export default useWidth
