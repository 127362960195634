import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { LayoutSpacing, Theme } from './theme'
import getResponsiveDirectionalValue from './theme-utils/get-responsive-directional-value'
import resolveLayoutSpacing from './theme-utils/resolve-layout-spacing'

export interface UseHorizontalSpacingProps {
  horizontalSpacing?: LayoutSpacing | Array<LayoutSpacing>
}

const useHorizontalSpacing = makeStyles((theme: Theme) => ({
  horizontalSpacingClass: ({ horizontalSpacing }: UseHorizontalSpacingProps): CreateCSSProperties =>
    getResponsiveDirectionalValue('marginRight', resolveLayoutSpacing(theme)(horizontalSpacing), theme),
}))

export default useHorizontalSpacing
