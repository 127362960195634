import { Theme } from '../theme'

import getResponsiveValue from './get-responsive-value'

function getResponsiveDirectionalValue<T extends number | string>(
  name: string,
  values: undefined | T | Array<T>,
  theme: Theme,
  // eslint-disable-next-line @typescript-eslint/ban-types
): {} {
  const responsiveValue = getResponsiveValue(name, values, theme)
  if (values !== undefined) {
    return {
      '&& > *': {
        ...responsiveValue,
      },
      '&& > *:last-child': {
        [name]: theme.layoutSpacing.none,
      },
    }
  }
  return responsiveValue
}

export default getResponsiveDirectionalValue
