import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { Theme } from './theme'
import getResponsiveValue from './theme-utils/get-responsive-value'

type OverflowType = 'visible' | 'hidden' | 'scroll'

export interface UseOverflowProps {
  overflow?: OverflowType
}

const useOverflow = makeStyles((theme: Theme) => ({
  overflowClass: ({ overflow }: UseOverflowProps): CreateCSSProperties =>
    getResponsiveValue('overflow', overflow, theme),
}))

export default useOverflow
