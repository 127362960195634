import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { LayoutSpacing, Theme } from './theme'
import getResponsiveDirectionalValue from './theme-utils/get-responsive-directional-value'
import resolveLayoutSpacing from './theme-utils/resolve-layout-spacing'

export interface UseVerticalSpacingProps {
  verticalSpacing?: LayoutSpacing | Array<LayoutSpacing>
}

const useVerticalSpacing = makeStyles((theme: Theme) => ({
  verticalSpacingClass: ({ verticalSpacing }: UseVerticalSpacingProps): CreateCSSProperties =>
    getResponsiveDirectionalValue('marginBottom', resolveLayoutSpacing(theme)(verticalSpacing), theme),
}))

export default useVerticalSpacing
