import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { FontSizing, Theme } from '../../design-tokens/theme'
import Icon from '../Icon'
import List, { ListProps } from '../List'
import ListItem from '../ListItem'
import Text from '../Text'

import getBullet from './get-bullet'

// Add further if needed here
export type Bullet = 'disc' | 'check-mark'

interface Props extends ListProps {
  bullet?: Bullet
  bulletSize?: FontSizing | Array<FontSizing>
  className?: string
  children: Array<React.ReactElement>
}

type UnorderedListRef = HTMLElementTagNameMap['ul']

const useStyles = makeStyles(({ layoutSpacing }: Theme) => ({
  listItem: {
    paddingBottom: layoutSpacing.none,
  },
  bullet: {
    marginTop: 2,
  },
}))

// eslint-disable-next-line no-shadow
export const UnorderedList = React.forwardRef<UnorderedListRef, Props>(function UnorderedList(
  { className, children, bullet, bulletSize },
  ref,
) {
  const classes = useStyles()
  const bulletIcon = getBullet(bullet!)
  return (
    <List className={className} ref={ref}>
      {React.Children.map(children, (child, index) => (
        <ListItem key={index} alignItems="flex-start" className={classes.listItem}>
          <Text is="span" marginRight="dense" className={classes.bullet}>
            <Icon icon={bulletIcon} size={bulletSize} />
          </Text>
          {child}
        </ListItem>
      ))}
    </List>
  )
})

UnorderedList.defaultProps = {
  bullet: 'disc',
  bulletSize: 'default',
}

export default UnorderedList
