import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { Space, Theme } from './theme'
import getResponsiveValue from './theme-utils/get-responsive-value'

export interface UseMaxWidthProps {
  maxWidth?: Space | Array<Space>
}

const useMaxWidth = makeStyles((theme: Theme) => ({
  maxWidthClass: ({ maxWidth }: UseMaxWidthProps): CreateCSSProperties =>
    getResponsiveValue('maxWidth', maxWidth, theme),
}))

export default useMaxWidth
