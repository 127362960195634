import { makeStyles, CreateCSSProperties } from '@mui/styles'

interface Props {
  fontWeight?: 'normal' | 'bold'
}

const useFontWeight = makeStyles({
  fontWeightClass: ({ fontWeight }: Props): CreateCSSProperties => ({
    fontWeight,
  }),
})

export default useFontWeight
