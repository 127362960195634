import { assertNever } from '../../utilities/assertNever'
import { Icons } from '../Icon'
import CircleIcon from '../Icon/custom-icons/Circle'

import { Bullet } from './UnorderedList'

/**
 * Get UnorderedList's bullet according to the passed props
 */
function getBullet(bullet: Bullet) {
  switch (bullet) {
    case 'disc':
      return CircleIcon
    case 'check-mark':
      return Icons.CHECK
    default:
      return assertNever(bullet)
  }
}

export default getBullet
