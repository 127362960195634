import MuiSvgIcon, { SvgIconProps as MuiSvgIconProps } from '@mui/material/SvgIcon'
import * as React from 'react'

type SvgIconRef = SVGSVGElement
export interface SvgIconProps extends Omit<MuiSvgIconProps, 'color' | 'fontSize'> {
  ref?: React.Ref<SvgIconRef>
}

// eslint-disable-next-line no-shadow
const SvgIcon = React.forwardRef<SvgIconRef, SvgIconProps>(function SvgIcon({ ...props }, ref) {
  return <MuiSvgIcon {...props} ref={ref} />
})

export default SvgIcon
