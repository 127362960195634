import CssBaseline from '@mui/material/CssBaseline'
import * as React from 'react'

import ResetCSS from './ResetCSS'

const Baseline = () => {
  return (
    <>
      <CssBaseline />
      <ResetCSS />
    </>
  )
}

export default Baseline
