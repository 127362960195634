import { FontSizing, Theme } from '../theme'

const resolveFontSizing =
  (theme: Theme) =>
  (fontSizings?: FontSizing | Array<FontSizing>): undefined | string | Array<string> => {
    if (!fontSizings) return undefined

    if (!Array.isArray(fontSizings)) {
      return theme.fontSizing[fontSizings]
    }

    return fontSizings.map((fontSizing) => resolveFontSizing(theme)(fontSizing) as string)
  }

export default resolveFontSizing
