import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { Theme } from '../../design-tokens/theme'
import Flex from '../Flex'

const useStyles = makeStyles(({ palette, borders }: Theme) => ({
  root: {
    height: 1,
    backgroundColor: palette.divider,
    border: borders.none,
  },
}))

const DividerLine: React.FC<React.PropsWithChildren<unknown>> = () => {
  const classes = useStyles()
  return <Flex flexGrow={1} className={classes.root} marginBottom="none" marginTop="none" is="hr" />
}

export default DividerLine
