import { makeStyles, CreateCSSProperties } from '@mui/styles'

export interface UseNoWrapProps {
  noWrap?: boolean
}

const useNoWrap = makeStyles({
  noWrapClass: ({ noWrap }: UseNoWrapProps): CreateCSSProperties => {
    if (!noWrap) return {}

    return {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }
  },
})

export default useNoWrap
