import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'
import { CSSProperties } from 'react'

import { filterProps } from '../../design-tokens/design-utils'
import useColor, { UseColorProps } from '../../design-tokens/useColor'
import useNoWrap, { UseNoWrapProps } from '../../design-tokens/useNoWrap'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'
import useTextAlign, { UseTextAlignProps } from '../../design-tokens/useTextAlign'
import useTextTransform, { UseTextTransformProps } from '../../design-tokens/useTextTransform'

type HeadingComponents = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export const variants = {
  small: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.5rem',
  },
  normal: {
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '1.75rem',
  },
  large: {
    fontSize: '2rem',
    fontWeight: 700,
    lineHeight: '3rem',
  },
  'x-large': {
    fontSize: '3rem',
    fontWeight: 700,
    lineHeight: '4rem',
  },
}

const useStyles = makeStyles(variants)

type Variant = keyof typeof variants

export interface HeadingProps
  extends UseSpacingProps,
    UseTextTransformProps,
    UseTextAlignProps,
    UseNoWrapProps,
    Pick<UseColorProps, 'color'> {
  is: HeadingComponents
  style?: CSSProperties
  alternativeColor?: boolean
  variant?: Variant
  className?: string
  children?: React.ReactNode
}

// The any below is not a problem, because we are only passing down the reference of the parent component
// The reference is already from type of the Component, definied through of the prop 'is'
// eslint-disable-next-line no-shadow
export const Heading = React.forwardRef<any, HeadingProps>(function Heading(
  { className, is, alternativeColor, variant, color, textTransform, textAlign, noWrap, ...props },
  ref,
) {
  const classes = useStyles()
  const { textTransformClass } = useTextTransform({ textTransform })
  const { textAlignClass } = useTextAlign({ textAlign })
  const { noWrapClass } = useNoWrap({ noWrap })
  const { spacingClass } = useSpacing(props)
  const { colorClass } = useColor({ color: alternativeColor ? 'alternativeText' : color })
  const Component = is

  return (
    <Component
      className={classNames(
        className,
        classes[variant!],
        colorClass,
        spacingClass,
        textAlignClass,
        textTransformClass,
        noWrapClass,
      )}
      ref={ref}
      {...filterProps(props, ...UseSpacingProp)}
    />
  )
})

Heading.defaultProps = {
  variant: 'normal',
  color: 'text.primary',
  textTransform: 'none',
  noWrap: false,
}

export default Heading
