import classNames from 'classnames'
import * as React from 'react'

import { assertNever } from '../../utilities/assertNever'
import { filterProps } from '../../design-tokens/design-utils'
import useHeight, { UseHeightProps } from '../../design-tokens/useHeight'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'
import useTheme from '../../design-tokens/useTheme'
import useWidth, { UseWidthProps } from '../../design-tokens/useWidth'

import getLogoColors, { LogoColor } from './get-logo-colors'
import EversportsLogoHorizontal from './logos/EversportsLogoHorizontal'
import EversportsLogoIcon from './logos/EversportsLogoIcon'
import EversportsLogoVertical from './logos/EversportsLogoVertical'

type LogoType = 'icon' | 'horizontal' | 'vertical'
type LogoRef = SVGElementTagNameMap['svg']
export interface Props extends UseSpacingProps, UseHeightProps, UseWidthProps {
  type?: LogoType
  color?: LogoColor
  className?: string
}

// eslint-disable-next-line no-shadow
export const Logo = React.forwardRef<LogoRef, Props>(function Logo(
  { type = 'icon', color = 'default', width, height, className, ...props },
  ref,
) {
  const theme = useTheme()
  const colors = getLogoColors(color, theme)

  const { widthClass } = useWidth({ width })
  const { heightClass } = useHeight({ height })
  const { spacingClass } = useSpacing(props)

  const componentProps = {
    ...filterProps(props, ...UseSpacingProp),
    colors,
    ref,
    className: classNames(className, widthClass, heightClass, spacingClass),
  }

  switch (type) {
    case 'icon':
      return <EversportsLogoIcon {...componentProps} />
    case 'horizontal':
      return <EversportsLogoHorizontal {...componentProps} />
    case 'vertical':
      return <EversportsLogoVertical {...componentProps} />
    default:
      return assertNever(type)
  }
})

export default Logo
