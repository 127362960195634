import * as React from 'react'

type HTMLElementTagName = Extract<keyof HTMLElementTagNameMap, string>
export interface DynamicComponentProps<R extends HTMLElementTagName = 'div'> {
  is?: R
  ref?: React.Ref<HTMLElementTagNameMap[R]>
  className?: string
  onClick?: () => void
  children?: React.ReactNode
}
class DynamicComponent<T extends HTMLElementTagName> extends React.PureComponent<DynamicComponentProps<T>> {
  public render() {
    const { is: Component = 'div', ...rest } = this.props
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return <Component {...rest} />
  }
}

export default DynamicComponent
