import { CookieSerializeOptions, parse } from 'cookie'
import * as React from 'react'

interface CookieType {
  [key: string]: string
}

export interface CookieContextType {
  readonly __updateCount__: number
  set: (cookieName: string, cookieValue: string, cookieOptions?: CookieSerializeOptions) => void
  get: (cookieName: string) => string | undefined
}

const CookieContext = React.createContext<CookieContextType>({
  set: () => undefined,
  get: () => undefined,
  __updateCount__: -1,
})

export function getCookies(searchCookies: Array<string>, cookieString: string): CookieType {
  const parsedCookies = parse(cookieString)
  return searchCookies.reduce(
    (cookies, cookieName) =>
      parsedCookies[cookieName] ? { ...cookies, [cookieName]: parsedCookies[cookieName] } : cookies,
    {},
  )
}

export default CookieContext
