import * as styledComponents from 'styled-components'

import { Theme } from './theme'

interface StyledComponentsExport extends styledComponents.ThemedStyledComponentsModule<Theme> {
  ServerStyleSheet: typeof styledComponents.ServerStyleSheet
}

const {
  default: styled,
  css,
  keyframes,
  ThemeProvider,
  withTheme,
  createGlobalStyle,
  ServerStyleSheet,
} = styledComponents as unknown as StyledComponentsExport

export { withTheme, styled, css, keyframes, ThemeProvider, createGlobalStyle, ServerStyleSheet }
