import { makeStyles, CreateCSSProperties } from '@mui/styles'
import deepmerge from 'deepmerge'

import { LayoutSpacing, Theme } from './theme'
import { getResponsiveValue } from './theme-utils'
import resolveLayoutSpacing from './theme-utils/resolve-layout-spacing'

export interface UseSpacingProps {
  margin?: LayoutSpacing | Array<LayoutSpacing>
  marginLeft?: LayoutSpacing | Array<LayoutSpacing>
  marginRight?: LayoutSpacing | Array<LayoutSpacing>
  marginBottom?: LayoutSpacing | Array<LayoutSpacing>
  marginTop?: LayoutSpacing | Array<LayoutSpacing>
  padding?: LayoutSpacing | Array<LayoutSpacing>
  paddingLeft?: LayoutSpacing | Array<LayoutSpacing>
  paddingRight?: LayoutSpacing | Array<LayoutSpacing>
  paddingBottom?: LayoutSpacing | Array<LayoutSpacing>
  paddingTop?: LayoutSpacing | Array<LayoutSpacing>
}

// TODO -> create a filterSpacingProps method
export const UseSpacingProp: Array<keyof UseSpacingProps> = [
  'margin',
  'marginLeft',
  'marginRight',
  'marginBottom',
  'marginTop',
  'padding',
  'paddingLeft',
  'paddingRight',
  'paddingBottom',
  'paddingTop',
]

const useSpacing = makeStyles((theme: Theme) => ({
  spacingClass: (props: UseSpacingProps) =>
    deepmerge.all([
      getResponsiveValue('margin', resolveLayoutSpacing(theme)(props.margin), theme),
      getResponsiveValue('marginBottom', resolveLayoutSpacing(theme)(props.marginBottom), theme),
      getResponsiveValue('marginLeft', resolveLayoutSpacing(theme)(props.marginLeft), theme),
      getResponsiveValue('marginRight', resolveLayoutSpacing(theme)(props.marginRight), theme),
      getResponsiveValue('marginTop', resolveLayoutSpacing(theme)(props.marginTop), theme),
      getResponsiveValue('padding', resolveLayoutSpacing(theme)(props.padding), theme),
      getResponsiveValue('paddingBottom', resolveLayoutSpacing(theme)(props.paddingBottom), theme),
      getResponsiveValue('paddingLeft', resolveLayoutSpacing(theme)(props.paddingLeft), theme),
      getResponsiveValue('paddingRight', resolveLayoutSpacing(theme)(props.paddingRight), theme),
      getResponsiveValue('paddingTop', resolveLayoutSpacing(theme)(props.paddingTop), theme),
    ]) as CreateCSSProperties,
}))

export default useSpacing
