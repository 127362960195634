import * as React from 'react'

import { Colors } from '../get-logo-colors'

type EversportsLogoVerticalRef = SVGElementTagNameMap['svg']

interface Props {
  colors: Colors
  className?: string
  ref?: React.Ref<EversportsLogoVerticalRef>
}

const EversportsLogoVertical: React.FC<React.PropsWithChildren<Props>> = ({ colors, className, ref }) => (
  <svg viewBox="0 0 495 343" className={className} ref={ref}>
    <title>Eversports</title>
    <path
      d="M342.944 135.45C343.625 134.424 344.673 134.144 345.469 134.448C345.855 134.596 346.086 134.848 346.189 135.086C346.276 135.286 346.34 135.617 346.15 136.194C332.902 176.333 299.443 200.108 263.006 205.04C226.583 209.971 187.552 195.989 163.589 161.081C150.069 140.937 145.667 122.049 147.337 105.587C149.006 89.1333 156.772 74.7908 168.11 63.7264C190.864 41.5224 227.725 32.8258 256.852 47.2651C257.304 47.4891 257.46 47.7737 257.519 47.9681C257.583 48.1821 257.581 48.4661 257.467 48.7918C257.239 49.4383 256.593 50.1292 255.581 50.0854C235.337 49.2099 220.026 55.5526 209.82 66.0452C199.599 76.5534 194.706 91.0152 195.035 105.848C195.693 135.492 217.097 166.461 258.134 172.187C277.543 174.895 317.343 174.037 342.944 135.45Z"
      fill={colors.iconTopColor}
    />
    <path
      d="M166.69 45.2332C165.744 46.044 164.993 45.8219 164.684 45.5148C164.526 45.3577 164.465 45.1774 164.463 45.005C164.46 44.8417 164.508 44.4878 164.884 43.9597C200.669 -6.23456 284.126 -21.7655 332.224 42.0274C346.832 61.403 349.531 80.8394 345.043 97.8619C340.529 114.986 328.633 130.085 313.39 140.473C282.83 161.298 239.898 162.559 218.55 124.445C218.201 123.822 218.298 123.172 218.667 122.633C219.058 122.062 219.725 121.686 220.458 121.871C246.761 128.514 266.501 126.219 279.863 118.643C293.324 111.012 300.209 98.0545 300.576 84.0433C301.303 56.2647 276.602 25.7086 230.859 23.8041C203.759 22.6758 184.111 30.2932 166.69 45.2332Z"
      fill={colors.iconBottomColor}
    />
    <path
      d="M473.74 263.104C470.932 263.104 468.258 263.371 465.851 264.04C463.311 264.708 461.037 265.644 459.165 266.981C457.16 268.319 455.555 270.057 454.486 272.196C453.282 274.336 452.747 276.742 452.747 279.684C452.747 282.626 453.282 285.032 454.352 287.038C455.422 289.044 456.892 290.648 458.631 291.852C460.369 293.055 462.375 294.125 464.514 294.927C466.653 295.729 468.793 296.398 470.932 297.066C473.071 297.735 475.077 298.27 476.815 299.072C478.554 299.741 480.024 300.677 481.094 301.746C482.164 302.816 482.699 304.153 482.832 305.758C482.832 307.229 482.431 308.432 481.629 309.234C480.827 310.17 479.623 310.839 478.286 311.24C476.949 311.641 475.345 311.908 473.473 311.908C471.333 311.908 469.194 311.641 466.787 311.106C464.514 310.571 462.241 309.769 459.968 308.699C457.828 307.63 455.823 306.426 454.218 304.822L449.806 313.647C451.678 315.385 453.951 316.856 456.491 318.059C459.032 319.263 461.706 320.199 464.514 320.733C467.322 321.402 470.13 321.669 472.938 321.669C475.879 321.669 478.687 321.268 481.228 320.6C483.902 319.931 486.175 318.861 488.315 317.524C490.32 316.187 491.925 314.315 493.128 312.176C494.332 310.036 494.866 307.496 495 304.554C495 301.613 494.465 299.072 493.396 297.066C492.326 295.061 490.855 293.456 489.117 292.119C487.379 290.782 485.373 289.846 483.234 288.91C481.094 288.108 478.955 287.306 476.682 286.771C474.542 286.102 472.537 285.567 470.665 284.899C468.926 284.23 467.456 283.428 466.252 282.492C465.183 281.556 464.648 280.353 464.514 278.882C464.514 277.545 464.915 276.475 465.584 275.539C466.252 274.737 467.322 274.068 468.525 273.667C469.729 273.266 471.2 273.132 472.804 273.132C474.542 273.132 476.281 273.4 478.153 273.801C480.024 274.202 481.896 274.737 483.902 275.539C485.774 276.341 487.646 277.277 489.251 278.213L493.797 268.987C492.059 267.784 490.053 266.714 487.913 265.778C485.774 264.976 483.367 264.307 480.96 263.772C478.687 263.371 476.147 263.104 473.74 263.104Z"
      fill={colors.textColor}
    />
    <path
      d="M444.858 307.897C443.254 308.699 441.649 309.368 440.312 309.769C438.975 310.17 437.504 310.438 436.301 310.438C434.964 310.438 433.894 310.17 432.958 309.769C432.022 309.368 431.353 308.432 430.819 307.362C430.284 306.159 430.016 304.688 430.016 302.682V275.004H446.998V265.778H430.016V250H417.715C417.715 274.202 417.715 273.533 417.715 304.822C417.715 307.763 418.116 310.304 419.052 312.443C419.854 314.583 421.058 316.321 422.528 317.658C423.999 318.995 425.738 320.065 427.61 320.6C429.481 321.268 431.621 321.536 433.76 321.536C436.167 321.536 438.574 321.135 440.981 320.466C443.387 319.797 445.661 318.728 447.934 317.391L444.858 307.897Z"
      fill={colors.textColor}
    />
    <path
      d="M393.379 263.639H380.944V321.001H393.379V291.049C393.78 287.707 394.716 284.899 396.455 282.358C398.059 279.951 400.332 277.946 403.274 276.742C406.082 275.405 409.425 274.87 413.302 275.004V263.104C408.756 263.104 404.745 264.173 401.402 266.179C398.059 268.185 395.385 271.126 393.379 275.004V263.639V263.639Z"
      fill={colors.textColor}
    />
    <path
      d="M345.912 273.934C349.388 273.934 352.33 274.737 355.004 276.341C357.678 277.946 359.684 280.085 361.155 282.893C362.626 285.701 363.428 288.91 363.428 292.654C363.428 296.398 362.626 299.607 361.155 302.415C359.684 305.223 357.678 307.362 355.004 308.967C352.33 310.571 349.388 311.374 345.912 311.374C342.435 311.374 339.36 310.571 336.686 308.967C334.011 307.362 332.006 305.223 330.535 302.415C329.064 299.607 328.262 296.398 328.262 292.654C328.262 288.91 329.064 285.701 330.535 282.893C332.006 280.085 334.011 277.946 336.686 276.341C339.36 274.737 342.435 273.934 345.912 273.934ZM345.912 263.238C341.499 263.238 337.354 263.906 333.744 265.377C330.134 266.714 326.925 268.72 324.25 271.394C321.576 273.934 319.571 277.01 318.1 280.62C316.629 284.23 315.96 288.108 315.96 292.52C315.96 296.799 316.763 300.81 318.1 304.421C319.571 308.031 321.576 311.106 324.25 313.647C326.925 316.187 330 318.193 333.744 319.664C337.354 321.001 341.499 321.803 345.912 321.803C350.324 321.803 354.336 321.135 358.079 319.664C361.69 318.327 364.899 316.321 367.573 313.647C370.247 311.106 372.253 308.031 373.724 304.421C375.194 300.81 375.863 296.933 375.863 292.52C375.863 288.108 375.061 284.23 373.724 280.62C372.253 277.01 370.247 273.934 367.573 271.394C364.899 268.853 361.823 266.848 358.079 265.377C354.336 263.906 350.324 263.238 345.912 263.238Z"
      fill={colors.textColor}
    />
    <path
      d="M282.265 310.839C278.789 310.839 275.847 310.037 273.173 308.432C270.498 306.827 268.493 304.688 267.022 301.88C265.551 299.072 264.749 295.863 264.749 292.119C264.749 288.509 265.551 285.3 267.022 282.492C268.493 279.684 270.498 277.545 273.173 275.94C275.847 274.336 278.789 273.533 282.265 273.533C284.806 273.533 287.212 273.934 289.352 274.87C291.491 275.806 293.363 277.144 294.834 278.748C296.439 280.486 297.642 282.358 298.444 284.631C299.246 286.904 299.781 289.311 299.781 291.986C299.781 295.729 298.979 298.939 297.508 301.746C296.037 304.554 294.032 306.694 291.357 308.298C288.683 310.037 285.742 310.839 282.265 310.839ZM284.806 263.238C280.259 263.238 276.382 264.174 273.039 265.912C269.696 267.65 266.888 270.191 264.749 273.667V263.772H252.314V342.127H264.749V311.106C266.888 314.449 269.563 316.99 273.039 318.728C276.382 320.466 280.393 321.402 285.073 321.402C289.218 321.402 292.962 320.733 296.305 319.263C299.648 317.926 302.456 315.92 304.862 313.379C307.269 310.839 309.141 307.763 310.345 304.287C311.682 300.677 312.216 296.799 312.35 292.52C312.35 288.108 311.682 284.097 310.345 280.62C309.007 277.01 307.135 273.934 304.729 271.394C302.322 268.853 299.38 266.848 296.037 265.377C292.695 263.906 288.951 263.238 284.806 263.238Z"
      fill={colors.textColor}
    />
    <path
      d="M227.577 263.104C224.769 263.104 222.095 263.371 219.688 264.04C217.147 264.708 214.874 265.644 213.002 266.981C210.997 268.319 209.392 270.057 208.322 272.196C207.119 274.336 206.584 276.742 206.584 279.684C206.584 282.626 207.119 285.032 208.189 287.038C209.258 289.044 210.729 290.648 212.468 291.852C214.206 293.055 216.211 294.125 218.351 294.927C220.49 295.729 222.63 296.398 224.769 297.066C226.908 297.735 228.914 298.27 230.652 299.072C232.391 299.741 233.861 300.677 234.931 301.746C236.001 302.816 236.536 304.153 236.669 305.758C236.669 307.229 236.268 308.432 235.466 309.234C234.664 310.17 233.46 310.839 232.123 311.24C230.786 311.641 229.181 311.908 227.31 311.908C225.17 311.908 223.031 311.641 220.624 311.106C218.351 310.571 216.078 309.769 213.805 308.699C211.665 307.63 209.66 306.426 208.055 304.822L203.643 313.647C205.515 315.385 207.788 316.856 210.328 318.059C212.869 319.263 215.543 320.199 218.351 320.733C221.159 321.402 223.967 321.669 226.775 321.669C229.716 321.669 232.524 321.268 235.065 320.6C237.739 319.931 240.012 318.861 242.151 317.524C244.157 316.187 245.762 314.315 246.965 312.176C248.169 310.036 248.703 307.496 248.837 304.554C248.837 301.613 248.302 299.072 247.233 297.066C246.163 295.061 244.692 293.456 242.954 292.119C241.216 290.782 239.21 289.846 237.07 288.91C234.931 288.108 232.792 287.306 230.519 286.771C228.379 286.102 226.374 285.567 224.502 284.899C222.763 284.23 221.293 283.428 220.089 282.492C219.019 281.556 218.485 280.353 218.351 278.882C218.351 277.545 218.752 276.475 219.421 275.539C220.089 274.737 221.159 274.068 222.362 273.667C223.566 273.266 225.036 273.132 226.641 273.132C228.379 273.132 230.117 273.4 231.989 273.801C233.861 274.202 235.733 274.737 237.739 275.539C239.611 276.341 241.483 277.277 243.087 278.213L247.634 268.987C245.895 267.784 243.89 266.714 241.75 265.778C239.611 264.976 237.204 264.307 234.797 263.772C232.391 263.371 229.984 263.104 227.577 263.104Z"
      fill={colors.textColor}
    />
    <path
      d="M183.452 263.639H171.017V321.001H183.452V291.049C183.853 287.707 184.789 284.899 186.527 282.358C188.132 279.951 190.405 277.946 193.347 276.742C196.155 275.405 199.498 274.87 203.375 275.004V263.104C198.829 263.104 194.818 264.173 191.475 266.179C188.132 268.185 185.458 271.126 183.452 275.004V263.639Z"
      fill={colors.textColor}
    />
    <path
      d="M121.143 287.974C121.677 284.899 122.613 282.225 124.084 280.085C125.555 277.812 127.561 276.208 129.834 275.004C132.241 273.801 134.915 273.266 137.857 273.132C141.066 273.132 143.74 273.801 146.013 274.87C148.286 276.074 150.158 277.812 151.495 279.951C152.832 282.225 153.634 284.765 153.768 287.974H121.143ZM137.857 263.238C133.578 263.238 129.7 264.04 126.09 265.377C122.48 266.714 119.538 268.72 116.864 271.394C114.323 273.934 112.318 277.01 110.847 280.62C109.51 284.23 108.707 288.108 108.707 292.52C108.707 296.799 109.376 300.81 110.847 304.287C112.184 307.897 114.19 310.839 116.73 313.513C119.271 316.054 122.346 318.059 125.956 319.396C129.566 320.734 133.578 321.536 137.99 321.536C142.938 321.536 147.484 320.733 151.495 318.995C155.506 317.257 158.983 314.984 161.791 311.775L155.239 304.822C153.1 306.961 150.693 308.699 147.885 309.769C145.077 310.973 142.135 311.507 139.06 311.507C135.985 311.507 133.31 310.973 130.904 309.769C128.497 308.566 126.357 306.961 124.753 304.822C123.148 302.682 121.945 300.142 121.276 297.2H164.599C165 291.986 164.733 287.306 163.93 283.161C163.128 279.016 161.523 275.405 159.384 272.464C157.245 269.522 154.303 267.249 150.693 265.644C147.216 264.04 142.938 263.238 137.857 263.238Z"
      fill={colors.textColor}
    />
    <path
      d="M89.3193 321.001L111.248 263.639H98.9465L83.1686 308.833L67.1232 263.639H54.1532L76.483 321.001H89.3193Z"
      fill={colors.textColor}
    />
    <path
      d="M12.4352 287.974C12.97 284.899 13.906 282.225 15.3768 280.085C16.8476 277.812 18.8533 276.208 21.1264 275.004C23.5332 273.801 26.2074 273.266 29.1491 273.132C32.3582 273.132 35.0324 273.801 37.3055 274.87C39.5786 276.074 41.4506 277.812 42.7877 279.951C44.1248 282.225 44.9271 284.765 45.0608 287.974H12.4352ZM29.1491 263.238C24.8703 263.238 20.9927 264.04 17.3825 265.377C13.906 266.714 10.8306 268.72 8.1564 271.394C5.61588 273.934 3.61021 277.01 2.13938 280.62C0.802267 284.23 0 288.108 0 292.52C0 296.799 0.668555 300.81 2.13938 304.287C3.4765 307.897 5.48217 310.839 8.02269 313.513C10.5632 316.054 13.6386 318.059 17.2488 319.396C20.859 320.734 24.8703 321.536 29.2828 321.536C34.2301 321.536 38.7763 320.733 42.7877 318.995C46.799 317.257 50.2755 314.984 53.0835 311.775L46.5316 304.822C44.3922 306.961 41.9854 308.699 39.1775 309.769C36.3695 310.973 33.4279 311.507 30.3525 311.507C27.2771 311.507 24.6029 310.973 22.1961 309.769C19.7893 308.566 17.6499 306.961 16.0454 304.822C14.4408 302.682 13.2374 300.142 12.5689 297.2H55.8914C56.2925 291.986 56.0251 287.306 55.2228 283.161C54.4206 279.016 52.816 275.405 50.6767 272.464C48.5373 269.522 45.5956 267.249 41.9854 265.644C38.5089 264.04 34.2301 263.238 29.1491 263.238Z"
      fill={colors.textColor}
    />
  </svg>
)

export default EversportsLogoVertical
