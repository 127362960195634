/**
 * Get text initials according to the passed initialsSize's prop
 */
function getInitials(text: string, initialsSize: number) {
  return text
    .trim()
    .split(/\s+/)
    .map((s) => s.charAt(0))
    .join('')
    .substring(0, initialsSize)
    .toUpperCase()
}

export default getInitials
