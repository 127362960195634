/* eslint-disable */
/* tslint:disable */
// @ts-nocheck

// DO NOT MODIFY
// FILE GENERATED BY `intl-codegen@2.2.1`
// https://github.com/eversport/intl-codegen
// DO NOT MODIFY

import { defineLoader } from "intl-codegen/runtime";

export const loadLanguage = defineLoader([
  "advantagesList1",
  "advantagesList2",
  "advantagesList3",
  "authenticationFacebookLoginError",
  "authenticationForgotPasswordAction",
  "authenticationGoogleLoginError",
  "autocompleteResultsNotFound",
  "autocompleteSearching",
  "back",
  "coronaGuidelines",
  "dateUnitDay",
  "dateUnitDayPlural",
  "dateUnitGame",
  "dateUnitGamePlural",
  "dateUnitHour",
  "dateUnitHourPlural",
  "dateUnitMinute",
  "dateUnitMinutePlural",
  "dateUnitMonth",
  "dateUnitMonthPlural",
  "dateUnitSeason",
  "dateUnitSeasonPlural",
  "dateUnitUnit",
  "dateUnitUnitPlural",
  "dateUnitWeek",
  "dateUnitWeekPlural",
  "dateUnitWeekend",
  "dateUnitWeekendPlural",
  "dateUnitYear",
  "dateUnitYearPlural",
  "dateRange",
  "dateTimeRange",
  "defaultError",
  "defaultErrorMessage",
  "errorMessage",
  "eversports",
  "familyAccountAccountOwner",
  "familyAccountBannerChangeUser",
  "familyAccountBannerCurrentlyBookingFor",
  "familyAccountBannerOwnerDisclaimer",
  "familyAccountContinueWithSelectedUser",
  "familyAccountCreateNewFamilyMember",
  "familyAccountDividerText",
  "familyAccountFamilyMember",
  "familyAccountNewMemberContinue",
  "familyAccountNewMemberDescription",
  "familyAccountNewMemberFirstNameLabel",
  "familyAccountNewMemberGoBack",
  "familyAccountNewMemberHeading",
  "familyAccountNewMemberLastNameLabel",
  "familyAccountSelectUser",
  "familyAccountSelectionHeading",
  "fullFormatDate",
  "longFormatDate",
  "mediumFormatDate",
  "monetaryValue",
  "networkErrorMessage",
  "newsletterBannerAction",
  "newsletterBannerActionSuccess",
  "newsletterBannerSubtitle",
  "newsletterBannerTitle",
  "next",
  "no",
  "noOption",
  "notFoundMessage",
  "notFoundTitle",
  "paymentUnitEuro",
  "privacyPolicies",
  "sameDayDateTimeRange",
  "shortFormatDate",
  "shortFormatTime",
  "temporaryEmailError",
  "termsOfService",
  "timeRange",
  "unexpectedErrorMessage",
  "yes",
], {
  de: () => import("./locales/de.js"),
  en: () => import("./locales/en.js"),
  es: () => import("./locales/es.js"),
  nl: () => import("./locales/nl.js"),
  fr: () => import("./locales/fr.js"),
  it: () => import("./locales/it.js"),
}, "en");
