import * as React from 'react'

import useSpacing, { UseSpacingProps } from '../../design-tokens/useSpacing'
import Flex from '../Flex'
import Text from '../Text'

import DividerLine from './DividerLine'

interface Props extends UseSpacingProps {
  children?: React.ReactNode
}

// The default element type of Flex is 'div' and we don't allow the change of this prop
type DividerRef = HTMLElementTagNameMap['div']

// eslint-disable-next-line no-shadow
export const Divider = React.forwardRef<DividerRef, Props>(function Divider({ children, ...props }, ref) {
  const { spacingClass } = useSpacing(props)
  return (
    <Flex alignItems="center" className={spacingClass} ref={ref} fullWidth>
      <DividerLine />
      {children && (
        <>
          <Text
            is="div"
            color="divider"
            size="x-small"
            textTransform="uppercase"
            marginRight="default"
            marginLeft="default"
          >
            {children}
          </Text>
          <DividerLine />
        </>
      )}
    </Flex>
  )
})

export default Divider
