import { makeStyles } from '@mui/styles'
import * as React from 'react'

import { assertNever } from '../../utilities/assertNever'
import BaseLink, { BaseLinkProps } from '../BaseLink'
import Text, { TextProps } from '../Text'

export interface Props extends BaseLinkProps, Pick<TextProps, 'useHover' | 'size' | 'fontWeight' | 'onClick'> {
  color?: 'secondary' | 'subtle' | 'white'
}

type LinkRef = HTMLElementTagNameMap['a']

const useStyles = makeStyles({
  inline: {
    display: 'inline',
  },
})

function mapColor(color: Exclude<Props['color'], undefined>): string {
  switch (color) {
    case 'subtle':
      return 'subtleText'
    case 'secondary':
      return 'secondary.main'
    case 'white':
      return 'white'
    default:
      return assertNever(color)
  }
}

// eslint-disable-next-line no-shadow
export const Link = React.forwardRef<LinkRef, Props>(function Link(
  { color, className, useHover, size, fontWeight, children, ...rest },
  ref,
) {
  const classes = useStyles()

  return (
    <BaseLink {...rest} ref={ref}>
      <Text
        is="span"
        fontWeight={fontWeight}
        color={mapColor(color!)}
        useHover={useHover}
        size={size}
        className={classes.inline}
      >
        {children}
      </Text>
    </BaseLink>
  )
})

Link.defaultProps = {
  color: 'secondary',
  fontWeight: 'normal',
  size: 'normal',
  useHover: true,
  external: false,
}

export default Link
