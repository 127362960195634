import { makeStyles, CreateCSSProperties } from '@mui/styles'
import classNames from 'classnames'
import deepmerge from 'deepmerge'
import * as React from 'react'

import { filterProps } from '../../design-tokens/design-utils'
import { styled } from '../../design-tokens/styled-components'
import { LayoutSpacing, Theme } from '../../design-tokens/theme'
import getResponsiveValue from '../../design-tokens/theme-utils/get-responsive-value'
import useFlexChild, { UseFlexChildProps } from '../../design-tokens/useFlexChild'
import useFullWidth, { UseFullWidthProps } from '../../design-tokens/useFullWidth'
import useHorizontalSpacing, { UseHorizontalSpacingProps } from '../../design-tokens/useHorizontalSpacing'
import useMaxWidth, { UseMaxWidthProps } from '../../design-tokens/useMaxWidth'
import useOverflow, { UseOverflowProps } from '../../design-tokens/useOverflow'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'
import useVerticalSpacing, { UseVerticalSpacingProps } from '../../design-tokens/useVerticalSpacing'
import useWidth, { UseWidthProps } from '../../design-tokens/useWidth'
import DynamicComponent, { DynamicComponentProps } from '../Internal/DynamicComponent'

type DisplayType = 'none' | 'flex' | 'inline-flex'
type FlexDirectionType = 'row' | 'row-reverse' | 'column' | 'column-reverse'
type FlexWrapType = 'nowrap' | 'wrap' | 'wrap-reverse'
type JustifyContentType = 'flex-start' | 'flex-end' | 'center' | 'space-between' | 'space-around' | 'space-evenly'
type AlignItemsType = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline'
type AlignContentType = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'space-between' | 'space-around'

interface FlexOnlyProps {
  display?: DisplayType | Array<DisplayType>
  flexDirection?: FlexDirectionType | Array<FlexDirectionType>
  flexWrap?: FlexWrapType | Array<FlexWrapType>
  justifyContent?: JustifyContentType | Array<JustifyContentType>
  alignItems?: AlignItemsType | Array<AlignItemsType>
  alignContent?: AlignContentType | Array<AlignContentType>
}

export interface FlexProps<T extends Extract<keyof HTMLElementTagNameMap, string>>
  extends DynamicComponentProps<T>,
    UseSpacingProps,
    UseFullWidthProps,
    UseWidthProps,
    UseMaxWidthProps,
    UseVerticalSpacingProps,
    UseHorizontalSpacingProps,
    UseOverflowProps,
    FlexOnlyProps,
    UseFlexChildProps {
  className?: string
  onClick?: () => void
  children?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) => ({
  flexClass: (props: FlexOnlyProps) =>
    deepmerge.all([
      getResponsiveValue('display', props.display, theme),
      getResponsiveValue('flex-direction', props.flexDirection, theme),
      getResponsiveValue('flex-wrap', props.flexWrap, theme),
      getResponsiveValue('align-items', props.alignItems, theme),
      getResponsiveValue('align-content', props.alignContent, theme),
      getResponsiveValue('justify-content', props.justifyContent, theme),
    ]) as CreateCSSProperties,
}))

function printAttribute(property: string | undefined, value: string | number): string {
  return property ? `${property}: ${value};` : value.toString()
}

function createResponsiveProperty<T extends string | number>(
  property: string | undefined,
  values: undefined | T | Array<T>,
  theme: Theme,
): undefined | string | { [key: string]: string } {
  if (!values) {
    return undefined
  }
  if (Array.isArray(values)) {
    if (values.length === 0) {
      return undefined
    }
    const result: Record<string, string> = { base: printAttribute(property, values[0]) }
    for (let i = 1; i < values.length; i++) {
      result[theme.breakpoints.up(theme.breakpoints.keys[i])] = printAttribute(property, values[i])
    }
    return result
  }
  return printAttribute(property, values)
}

// Think about parsing this to a temporary format (keeping the object format )
// and creating a function handling just the printing of this
function mergeResponsiveProperties(...values: Array<ReturnType<typeof createResponsiveProperty>>): string {
  let style = ''
  const map = new Map<string, string>()
  for (const value of values) {
    if (typeof value === 'string') {
      style += value
    } else if (typeof value === 'object') {
      for (const key of Object.keys(value)) {
        if (key === 'base') {
          style += value[key]
        } else {
          const styles = (map.get(key) || '') + value[key]
          map.set(key, styles)
        }
      }
    }
  }

  for (const [media, styles] of map) {
    style += `${media}{${styles}}`
  }

  return style
}

// Responsive helpers for other possible wrappers or mixins
function convertValue<T, R extends string | number>(
  value: T | Array<T>,
  converter: (value: T, theme: Theme) => R,
  theme: Theme,
): undefined | R | Array<R> {
  if (!value) return undefined
  if (Array.isArray(value)) {
    return value.map((val) => converter(val, theme))
  }
  return converter(value, theme)
}

/*
function convertFullWidth(value?: boolean): string {
  return value ? '100%' : 'initial'
}
function fullWidthStyle(props: UseFullWidthProps & { theme: Theme }) {
  const fullWidth = convertValue(props.fullWidth, convertFullWidth, props.theme)
  return createResponsiveProperty('width', fullWidth, props.theme)
}
*/

function convertVerticalSpacing(value: LayoutSpacing, theme: Theme): string {
  return `& > * + * { margin-top: ${theme.layoutSpacing[value]}; }`
}
function verticalSpacingStyle(props: UseVerticalSpacingProps & { theme: Theme }) {
  const verticalSpacing =
    props.verticalSpacing && convertValue(props.verticalSpacing, convertVerticalSpacing, props.theme)
  return createResponsiveProperty(undefined, verticalSpacing, props.theme)
}

function convertHorizontalSpacing(value: LayoutSpacing, theme: Theme): string {
  return `& > * + * { margin-left: ${theme.layoutSpacing[value]}; }`
}
function horizontalSpacingStyle(props: UseHorizontalSpacingProps & { theme: Theme }) {
  const horizontalSpacing =
    props.horizontalSpacing && convertValue(props.horizontalSpacing, convertHorizontalSpacing, props.theme)
  return createResponsiveProperty(undefined, horizontalSpacing, props.theme)
}

export const SimpleFlex = styled.div<FlexOnlyProps>`
  ${(props) =>
    mergeResponsiveProperties(
      createResponsiveProperty('display', props.display, props.theme),
      createResponsiveProperty('flex-direction', props.flexDirection, props.theme),
      createResponsiveProperty('flex-wrap', props.flexWrap, props.theme),
      createResponsiveProperty('align-items', props.alignItems, props.theme),
      createResponsiveProperty('align-content', props.alignContent, props.theme),
      createResponsiveProperty('justify-content', props.justifyContent, props.theme),
    )}
`

SimpleFlex.defaultProps = {
  display: 'flex',
}

export const VerticalFlex = styled(SimpleFlex)<FlexOnlyProps & UseVerticalSpacingProps>`
  ${(props) => mergeResponsiveProperties(verticalSpacingStyle(props))}
`

VerticalFlex.defaultProps = {
  display: 'flex',
  flexDirection: 'column',
}

export const HorizontalFlex = styled(SimpleFlex)<FlexOnlyProps & UseHorizontalSpacingProps>`
  ${(props) => mergeResponsiveProperties(horizontalSpacingStyle(props))}
`

HorizontalFlex.defaultProps = {
  display: 'flex',
}

// The any below is not a problem, because we are only passing down the reference of the parent component
// The reference is already from type of the Component, definied through of the prop 'is'
// eslint-disable-next-line no-shadow
export const Flex = React.forwardRef<any, FlexProps<keyof HTMLElementTagNameMap>>(function Flex(
  {
    width,
    fullWidth,
    maxWidth,
    display,
    flexDirection,
    flexWrap,
    alignItems,
    alignContent,
    justifyContent,
    flexGrow,
    flexShrink,
    alignSelf,
    verticalSpacing,
    horizontalSpacing,
    overflow,
    className,
    onClick,
    ...props
  },
  ref,
) {
  const classes = useStyles({
    display,
    flexDirection,
    flexWrap,
    alignItems,
    alignContent,
    justifyContent,
  })

  const { widthClass } = useWidth({ width })
  const { fullWidthClass } = useFullWidth({ fullWidth })
  const { maxWidthClass } = useMaxWidth({ maxWidth })
  const { flexChildClass } = useFlexChild({ flexGrow, flexShrink, alignSelf })
  const { spacingClass } = useSpacing(props)
  const { verticalSpacingClass } = useVerticalSpacing({ verticalSpacing })
  const { horizontalSpacingClass } = useHorizontalSpacing({ horizontalSpacing })
  const { overflowClass } = useOverflow({ overflow })

  return (
    <DynamicComponent
      onClick={onClick}
      className={classNames(
        className,
        classes.flexClass,
        widthClass,
        fullWidthClass,
        maxWidthClass,
        flexChildClass,
        spacingClass,
        verticalSpacingClass,
        horizontalSpacingClass,
        overflowClass,
      )}
      {...filterProps(props, ...UseSpacingProp)}
      ref={ref}
    />
  )
})

Flex.defaultProps = {
  is: 'div',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  flexGrow: 0,
  justifyContent: 'flex-start',
  alignItems: 'stretch',
  alignContent: 'stretch',
  overflow: 'visible',
}

export default Flex
