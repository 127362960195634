import { AvatarProps as MuiAvatarProps, default as MuiAvatar } from '@mui/material/Avatar'
import { makeStyles } from '@mui/styles'
import classNames from 'classnames'
import * as React from 'react'

import { filterProps } from '../../design-tokens/design-utils'
import useBackgroundColor from '../../design-tokens/useBackgroundColor'
import useFontSize, { UseFontSizeProps } from '../../design-tokens/useFontSize'
import useSpacing, { UseSpacingProp, UseSpacingProps } from '../../design-tokens/useSpacing'

import getInitials from './get-initials'

const avatarSizing = {
  'x-small': '24px',
  small: '32px',
  default: '48px',
  big: '80px',
}

type AvatarSizing = keyof typeof avatarSizing
// The default element type of MUI's Avatar is 'div' and we don't allow the change of this prop
type AvatarRef = HTMLElementTagNameMap['div']

export interface Props
  extends Pick<MuiAvatarProps, 'alt' | 'children' | 'classes' | 'imgProps' | 'sizes' | 'src' | 'srcSet'>,
    UseSpacingProps,
    UseFontSizeProps {
  size?: AvatarSizing
  initialsSize?: number
  backgroundColor?: 'default' | 'primary'
}

const useStyles = makeStyles({
  avatarClass: ({ size }: Pick<Props, 'size'>) => ({
    width: avatarSizing[size!],
    height: avatarSizing[size!],
  }),
})

// eslint-disable-next-line no-shadow
export const Avatar = React.forwardRef<AvatarRef, Props>(function Avatar(
  { children, fontSize, backgroundColor, initialsSize, ...props },
  ref,
) {
  const classes = useStyles(props)
  const { fontSizeClass } = useFontSize({ fontSize })
  const { spacingClass } = useSpacing(props)
  const { backgroundColorClass } = useBackgroundColor({
    backgroundColor: backgroundColor === 'primary' ? 'primary.main' : '',
  })
  return (
    <MuiAvatar
      {...filterProps(props, ...UseSpacingProp)}
      className={classNames(spacingClass, fontSizeClass, classes.avatarClass, backgroundColorClass)}
      ref={ref}
    >
      {typeof children === 'string' ? getInitials(children, initialsSize!) : children}
    </MuiAvatar>
  )
})

Avatar.defaultProps = {
  size: 'default',
  fontSize: 'small',
  initialsSize: 2,
  backgroundColor: 'default',
}

export default Avatar
