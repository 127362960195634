import { createTheme } from '@mui/material/styles'
import * as mediaQuery from 'css-mediaquery'

import FigtreeFontFace from './figtree-font-face'

export const palette = {
  primary: '#21a696',
  primaryLight: '#0cd6c2',
  secondary: '#7700ee',
  text: '#333333',
  subtleText: '#888a8d',
  white: '#ffffff',
  black: '#000000',
  alternativeText: '#ffffff',
  info: '#2d9cbb',
  warning: '#f2994a',
  danger: '#eb5757',
  border: '#cccccc',
  divider: '#cccccc',
  listHover: '#cccccc',
  transparent: 'transparent',
  disabled: '#e8e8e8',
  // Properties related to our eventual v2 theme
  lightGray: '#dddddd',
  mediumGray: '#bbbbbb',
  textGray: '#808080',
  darkGray: '#595959',
}

export type Palette = keyof typeof palette

// Keep space and spacing of compatibility reasons with styled-system
const fontSizes = [12, 14, 16, 20, 32, 48, 72]
export const fontSizing = {
  'x-small': '12px',
  small: '14px',
  default: '16px',
  large: '20px',
  'x-large': '32px',
  'xx-large': '48px',
  'xxx-large': '72px',
  // Add further if needed here
}
export type FontSizing = keyof typeof fontSizing

const space = {
  none: '0',
  '4px': '4px',
  '8px': '8px',
  '12px': '12px',
  '16px': '16px',
  '24px': '24px',
  '32px': '32px',
  '64px': '64px',
  '128px': '128px',
  '256px': '256px',
  '368px': '368px',
  '512px': '512px',
}
export type Space = keyof typeof space

const spacingUnit = 8

const layoutSpacing = {
  none: '0',
  auto: 'auto',
  dense: `${spacingUnit}px`,
  default: `${spacingUnit * 2}px`,
  wide: `${spacingUnit * 3}px`,
  hero: `${spacingUnit * 9}px`,
  // Add further if needed here
}
export type LayoutSpacing = keyof typeof layoutSpacing

const borders = { none: 'none', default: '1px solid', strong: '2px solid' }

export type Borders = keyof typeof borders

export const getBorderFromString = (borderKey: Borders): string => {
  if (borderKey === 'none') {
    return `border-width: 0;`
  }

  const border = borders[borderKey].split(' ')
  return `
    border-width: ${border[0]};
    border-style: ${border[1]};
    border-color: ${palette.border}
    `
}

const borderRadii = {
  none: '0',
  default: '4px',
  large: '8px',
}
export type BorderRadii = keyof typeof borderRadii

const transitionDurations = {
  none: '0',
  fast: '100ms',
  default: '200ms',
}

export type TransitionDurations = keyof typeof transitionDurations

const customShadows = {
  none: 'none',
  boxShadowDown:
    '0px 5px 5px -3px rgba(0,0,0,0.05), 0px 8px 10px 1px rgba(0,0,0,0.08), 0px 3px 14px 2px rgba(0,0,0,0.05)',
  boxShadowUp:
    '0px -5px 5px -3px rgba(0,0,0,0.05), 0px -8px 10px 1px rgba(0,0,0,0.08), 0px -3px 14px 2px rgba(0,0,0,0.05)',
}

export type CustomShadows = keyof typeof customShadows

function ssrMatchMedia(query: string): { matches: boolean } {
  return {
    matches: mediaQuery.match(query, {
      width: 800,
    }),
  }
}

const customTheme = {
  fontSizes,
  fontSizing,
  space,
  layoutSpacing,
  borders,
  borderRadii,
  transitionDurations,
  customShadows,
  MuiUseMediaQuery: { ssrMatchMedia },
}

export const theme = createTheme({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Multiple module augmentations for @mui/material/styles create issues with the types
  palette: {
    ...palette,
    primary: { main: palette.primary },
    secondary: { main: palette.secondary },
    error: { main: palette.danger },
    text: { primary: palette.text },
    warning: { main: palette.warning },
    info: { main: palette.info },
  },
  typography: {
    fontFamily: 'Figtree, Figtree Fallback',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: FigtreeFontFace,
    },
  },
  spacing: spacingUnit,
  ...customTheme,
})

export type CustomTheme = typeof customTheme
export type Theme = typeof theme

type CustomPalette = typeof palette

declare module '@mui/material/styles' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Theme extends CustomTheme {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface Palette extends CustomPalette {}
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface PaletteOptions extends Partial<CustomPalette> {}
}

const extendedTheme: Theme & CustomTheme = { ...theme, ...customTheme }

export default extendedTheme
