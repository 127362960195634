import { Theme } from '../theme'

interface Props {
  color?: string
}

function getInvalidMessage(color: string): undefined {
  console.error(`You passed an invalid color: ${color}`)
  return undefined
}

function isPaletteKey(palette: Theme['palette'], colorKey: string): colorKey is keyof Theme['palette'] {
  return colorKey in palette
}

const getColorValue =
  ({ palette }: Theme) =>
  ({ color }: Props): string | undefined => {
    if (!color) return undefined

    const [colorKey, colorVariant] = color.split('.')
    if (!isPaletteKey(palette, colorKey)) {
      return getInvalidMessage(color)
    }
    const colorValue = palette[colorKey]

    // Color is string ==> simple value
    if (typeof colorValue === 'string') {
      return colorValue
    }
    if (typeof colorValue !== 'object') {
      return getInvalidMessage(color)
    }

    // Color is object ==> complex value
    return colorValue[colorVariant as keyof typeof colorValue] || getInvalidMessage(color)
  }

export default getColorValue
