import { CookieSerializeOptions, serialize } from 'cookie'
import * as React from 'react'
import { useState } from 'react'

import CookieContext, { getCookies } from './cookie-context'

interface Props {
  allowedCookies: Array<string>
}

const CookieProviderClient: React.FC<React.PropsWithChildren<Props>> = ({ children, allowedCookies }) => {
  const [count, setCount] = useState(0)

  const set = (cookieName: string, cookieValue: string, cookieOptions?: CookieSerializeOptions) => {
    document.cookie = serialize(cookieName, cookieValue, cookieOptions)
    setCount(count + 1)
  }
  const get = (cookieName: string) => getCookies(allowedCookies, document.cookie || '')[cookieName]

  return <CookieContext.Provider value={{ set, get, __updateCount__: count }}>{children}</CookieContext.Provider>
}

export default CookieProviderClient
