import { LayoutSpacing, Theme } from '../theme'

const resolveLayoutSpacing =
  (theme: Theme) =>
  (layoutSpacings?: LayoutSpacing | Array<LayoutSpacing>): undefined | string | Array<string> => {
    if (!layoutSpacings) return undefined

    if (!Array.isArray(layoutSpacings)) {
      return theme.layoutSpacing[layoutSpacings]
    }

    return layoutSpacings.map((layoutSpacing) => resolveLayoutSpacing(theme)(layoutSpacing) as string)
  }

export default resolveLayoutSpacing
