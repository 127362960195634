import { makeStyles, CreateCSSProperties } from '@mui/styles'

import { Theme } from './theme'
import getResponsiveValue from './theme-utils/get-responsive-value'

type TextAlignType = 'center' | 'left' | 'right'

export interface UseTextAlignProps {
  textAlign?: TextAlignType | Array<TextAlignType>
}

const useTextAlign = makeStyles((theme: Theme) => ({
  textAlignClass: ({ textAlign }: UseTextAlignProps): CreateCSSProperties =>
    getResponsiveValue('text-align', textAlign, theme),
}))

export default useTextAlign
