import { default as MuiList, ListProps } from '@mui/material/List'
import * as React from 'react'

type ListRef = HTMLElementTagNameMap['ul']

// eslint-disable-next-line no-shadow
const List = React.forwardRef<ListRef, ListProps>(function List(props, ref) {
  return <MuiList {...props} disablePadding ref={ref} />
})

List.defaultProps = {
  disablePadding: true,
}

export { ListProps }

export default List
