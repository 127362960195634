import theme from '@eversports/react-components/design-tokens/theme'

import generateClassName from './generate-class-name'

function createStyleContextClient() {
  return {
    theme,
    generateClassName: generateClassName(),
  }
}

export default createStyleContextClient
